import React from "react";
// import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { GiRibbonMedal, GiHammerNails } from "react-icons/gi";
import { AiOutlineTeam } from "react-icons/ai";
// import { IoMdHammer } from "react-icons/io";
function HeroVideo({ urlVideo, title, texts }) {
    // const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:fl ex -row flex- col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[40px] md:text-start text-center text-white">
                    <div className="w-full md:w-full text-center md:text-center p-4">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{title}</h1>
                        <p className="px-5 md:px-0">{texts}</p>
                        <ButtonContent btnStyle="three" />
                    </div>

                    {/* <div className="md:w-1/2 w-full flex justify-center items-center">
                        <img alt="years" 
                        src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/14%20a%C3%B1os.png?alt=media&token=a53be362-d5d7-4ad8-85ea-63a66556d802" 
                        className="md:w-[300px] w-[180px]"/>
                    </div> */}
                </div>
            </div>
            <div className=" md:w-[70%] w-[100%] bg-2 text-white md:-mt-10 -mt-1 relative md:py-14 py-2 homi">
                <ul className="flex md:flex-row flex-col justify-around ml-5 md:ml-0">
                    <h5 className="flex items-center" data-aos="zoom-in">
                        <GiHammerNails
                            fontSize={70}
                            className="text-white"
                        />
                        BEST SERVICE</h5>
                    <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1000">
                        <AiOutlineTeam
                            fontSize={70}
                            className="text-white"
                        />
                        PROFESSIONAL TEAM</h5>
                    <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1500">
                        <GiRibbonMedal
                            fontSize={70}
                            className="text-white"
                        />BEST QUALITY</h5>
                </ul>
            </div>
        </div>
    );
}
export default HeroVideo;